import React, { useEffect, useState, useRef } from "react";
import DefaultLayout from "../../../layout/defaultlayout";
import ContentNav from "../../../datanavcontent";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import { Loader } from "rsuite";
import "./editproduct.css";
import { colors } from "../../addition/colors";
import ReactQuill from "react-quill";

// Import react-dnd components
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

// Define Drag and Drop Types
const ItemTypes = {
  IMAGE: "image",
};

const EditProduct = () => {
  const language = useSelector((state) => state.language.lang);
  const location = useLocation();
  const { record } = location.state;

  const [ColorsArr, setColorsArr] = useState([]);
  const [ProdDaWioutCol, setProdDaWioutCol] = useState({
    product_id: record.id,
    category_id: record.category_id,
    title: record.title,
    title_ar: record.title_ar,
    description: record.description,
    description_ar: record.description_ar,
    model_number: record.model_number,
    producing_company: record.producing_company,
    conditions: record.conditions,
    conditions_ar: record.conditions_ar,
    isReturned: record.isReturned,
    return_period: record.return_period,
    store: record.store,
    loading: false,
  });
  const [colorDeletedImages, setcolorDeletedImages] = useState([]);
  const [image, setimage] = useState(null);
  const [categories, setcategories] = useState([]);
  const [images, setimages] = useState([]);
  const [addloading, setaddloading] = useState(false);
  const [imageloading2, setimageloading2] = useState(false);

  // Draggable Image Component
  const DraggableImage = ({ img, index, moveImage, colorIndex, indimg }) => {
    const ref = useRef(null);

    const [, drop] = useDrop({
      accept: ItemTypes.IMAGE,
      hover(item, monitor) {
        if (!ref.current) {
          return;
        }
        const dragIndex = item.index;
        const hoverIndex = index;
        const dragColorIndex = item.colorIndex;

        // Only handle same color images
        if (dragColorIndex !== colorIndex) {
          return;
        }

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return;
        }

        // Determine rectangle on screen
        const hoverBoundingRect = ref.current.getBoundingClientRect();

        // Get horizontal middle
        const hoverMiddleX =
          (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the left
        const hoverClientX = clientOffset.x - hoverBoundingRect.left;

        // Only perform the move when the mouse has crossed half of the item's width
        if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
          return;
        }
        if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
          return;
        }

        // Time to actually perform the action
        moveImage(colorIndex, dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        item.index = hoverIndex;
      },
    });

    const [{ isDragging }, drag] = useDrag({
      type: ItemTypes.IMAGE,
      item: { type: ItemTypes.IMAGE, index, colorIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    drag(drop(ref));

    return (
      <div
        ref={ref}
        className="color_img"
        style={{
          opacity: isDragging ? 0.5 : 1,
          cursor: "move",
          position: "relative",
        }}
      >
        <img src={img.link} alt="" />
        <div
          onClick={() => {
            handledeletecolorimg(img, colorIndex, indimg);
          }}
          className="img_rem"
        >
          <AiOutlineClose />
        </div>
      </div>
    );
  };

  // Fetch Product by ID
  const getproductbyid = () => {
    axios
      .get(`https://api.manjam.shop/v4/product/getProduct?id=${record.id}`)
      .then((res) => {
        let allcolors = [...res.data.message[0]?.colors];
        let pushedcolors = allcolors.map((item, index) => {
          return { ...item, new: false };
        });
        for (let i = 0; i < allcolors.length; i++) {
          let allprops = allcolors[i]?.props;
          for (let j = 0; j < allprops?.length; j++) {
            let allvalues = allprops[j]?.values;
            for (let k = 0; k < allvalues?.length; k++) {
              allcolors[i].props[j].values[k]["new"] = false;
              allcolors[i].props[j].values[k]["loading"] = false;
            }
            allcolors[i].props[j]["new"] = false;
            allcolors[i].props[j]["loading"] = false;
          }
          allcolors[i]["new"] = false;
          allcolors[i]["loading"] = false;
        }
        setColorsArr(allcolors);
      })
      .catch((err) => {
        console.error("Error fetching product by ID:", err);
        toast.error(
          language === "ar" ? "حدث خطأ ما" : "Something went wrong"
        );
      });
  };

  // Initialize Product Data
  const eqdata = () => {
    setProdDaWioutCol({
      product_id: record.id,
      category_id: record.category_id,
      title: record.title,
      title_ar: record.title_ar,
      description: record.description,
      description_ar: record.description_ar,
      model_number: record.model_number,
      producing_company: record.producing_company,
      conditions: record.conditions,
      conditions_ar: record.conditions_ar,
      isReturned: record.isReturned,
      return_period: record.return_period,
      store: record.store,
      ...record,
    });
  };

  // Fetch Categories
  const getcategories = async () => {
    await axios
      .get("https://api.manjam.shop/v4/category/getAll?type=admin")
      .then((res) => {
        setcategories(res.data.message);
        setProdDaWioutCol({
          ...ProdDaWioutCol,
          category_id: res.data.message[0]?.id,
        });
      })
      .catch((err) => {
        console.error("Error fetching categories:", err);
        toast.error(
          language === "ar" ? "حدث خطأ ما" : "Something went wrong"
        );
      });
  };

  // Handle Image Upload for Existing Colors
  const handleuploadaftereditcolors = (i) => {
    if (!image) {
      toast.warn(
        language === "ar" ? "من فضلك اختر صورة للرفع" : "Please select an image to upload"
      );
      return;
    }

    setimageloading2(true);
    const formdata = new FormData();
    formdata.append("image", image);
    axios
      .post("https://image-uploader-ochre.vercel.app/image/upload", formdata)
      .then((res) => {
        if (res.data.imgUrl !== "") {
          let allcolorsData = [...ColorsArr];
          toast.success("Image uploaded successfully");
          setimages([...images, res.data.imgUrl]);
          let obj = {
            id: Date.now() + Math.random(), // Assign a unique ID
            link: res.data.imgUrl,
          };
          allcolorsData[i].images.push(obj);
          setColorsArr(allcolorsData);
          setimage(null); // Reset the image input
        }
      })
      .catch((err) => {
        console.error("Error uploading image:", err);
        toast.error(
          language === "ar" ? "فشل رفع الصورة" : "Image upload failed"
        );
      })
      .finally(() => {
        setimageloading2(false);
      });
  };

  // Since handleuploadaftereditcolors2 is identical, we can remove it
  // and use handleuploadaftereditcolors instead

  // Handle Color Change
  const handlechangecolor = (e, i) => {
    const list = [...ColorsArr];
    list[i][e.target.name] = e.target.value;
    setColorsArr(list);
  };

  // Handle Adding a New Color
  const handleaddcolor = (item) => {
    let images = "";
    if (item?.images?.length === 0) {
      toast.warn(
        language === "ar"
          ? "من فضلك إرفع صور للون أولا"
          : "Please upload images for the color first"
      );
      return;
    }
    images = item.images.map((img) => img.link).join('***');

    const data_send = {
      product_id: record.id,
      images,
      color: item.color,
      color_ar: item.color_ar,
      color_code: item.color_code,
    };
    let allcolors = [...ColorsArr];
    axios
      .post("https://api.manjam.shop/v4/color/add", data_send)
      .then((res) => {
        if (res.data.status === 1) {
          getproductbyid();
          toast.success(res.data.message);
          let pushedcolors = allcolors.map((it) => {
            if (item.color_code === it.color_code) {
              return { ...it, new: true };
            } else return { ...it };
          });
          setColorsArr(pushedcolors);
        } else if (res.data.status === 0) {
          toast.error(res.data.message);
        } else {
          toast.error(
            language === "ar" ? "حدث خطأ ما" : "Something went wrong"
          );
        }
      })
      .catch((err) => {
        console.error("Error adding color:", err);
        toast.error(
          language === "ar" ? "حدث خطأ ما" : "Something went wrong"
        );
      });
  };

  // Handle Property Form Change
  const handlechagepropform = (e, item, index, itProp, indProp) => {
    let allColorsData = [...ColorsArr];
    let list2 = [...allColorsData[index].props];
    list2[indProp][e.target.name] = e.target.value;
    allColorsData[index].props = list2;
    setColorsArr(allColorsData);
  };

  // Handle Adding a New Property
  const handleaddproperity = (itProp) => {
    const { color_id, label, label_ar } = itProp;
    let allcolors = [...ColorsArr];
    setColorsArr(
      allcolors.map((color) =>
        color.color_id === color_id ? { ...color, loading: true } : color
      )
    );
    const data_send = {
      color_id: color_id,
      label: label,
      label_ar: label_ar,
    };
    axios
      .post("https://api.manjam.shop/v4/color_props/add", data_send)
      .then((res) => {
        if (res.data.status === 1) {
          getproductbyid();
          toast.success(
            language === "ar" ? "تمت الإضافة بنجاح" : "Added Successfully"
          );
        } else if (res.data.status === 0) {
          toast.error(language === "ar" ? "لم تتم الإضافة" : "Not Added");
        } else {
          toast.error(
            language === "ar" ? "حدث خطأ ما" : "Something went wrong"
          );
        }
      })
      .catch((err) => {
        console.error("Error adding property:", err);
        toast.error(
          language === "ar" ? "حدث خطأ ما" : "Something went wrong"
        );
      })
      .finally(() => {
        let allcolors = [...ColorsArr];
        setColorsArr(
          allcolors.map((color) =>
            color.color_id === color_id ? { ...color, loading: false } : color
          )
        );
      });
  };

  // Handle Adding a New Property Value
  const handleaddPropsValue = (data) => {
    const data_send = {
      label: data.label,
      label_ar: data.label_ar,
      prop_id: data.prop_id,
    };
    axios
      .post("https://api.manjam.shop/v4/color_props/add_value", data_send)
      .then((res) => {
        if (res.data.status === 1) {
          getproductbyid();
          toast.success(res.data.message);
        } else if (res.data.status === 0) {
          toast.error(res.data.message);
        } else {
          toast.error(
            language === "ar" ? "حدث خطأ ما" : "Something went wrong"
          );
        }
      })
      .catch((err) => {
        console.error("Error adding property value:", err);
        toast.error(
          language === "ar" ? "حدث خطأ ما" : "Something went wrong"
        );
      });
  };

  // Handle Editing a Property Value
  const handleeditpropvalue = (itPropValue) => {
    console.log(itPropValue);
    let allcolors = [...ColorsArr];
    for (let color of allcolors) {
      for (let prop of color.props) {
        for (let value of prop.values) {
          if (value.id === itPropValue.id) {
            value.loading = true;
          }
        }
      }
    }
    setColorsArr(allcolors);
    const data_send = {
      label: itPropValue.label,
      label_ar: itPropValue.label_ar,
      prop_value_id: itPropValue.id,
    };
    axios
      .post("https://api.manjam.shop/v4/color_props/edit_value", data_send)
      .then((res) => {
        if (res.data.status === 1) {
          toast.success(res.data.message);
          getproductbyid();
        } else if (res.data.status === 0) {
          toast.error(res.data.message);
        } else {
          toast.error(
            language === "ar" ? "حدث خطأ ما" : "Something went wrong"
          );
        }
      })
      .catch((err) => {
        console.error("Error editing property value:", err);
        toast.error(
          language === "ar" ? "حدث خطأ ما" : "Something went wrong"
        );
      })
      .finally(() => {
        setaddloading(false);
        let allcolors = [...ColorsArr];
        for (let color of allcolors) {
          for (let prop of color.props) {
            for (let value of prop.values) {
              if (value.id === itPropValue.id) {
                value.loading = false;
              }
            }
          }
        }
        setColorsArr(allcolors);
      });
  };

  // Handle Changing a Property Value
  const handlechangeprpvalue = (e, index, indProp, indPropValue) => {
    let allColorsArrData = [...ColorsArr];
    let list = allColorsArrData[index].props[indProp].values[indPropValue];
    list[e.target.name] = e.target.value;
    allColorsArrData[index].props[indProp].values[indPropValue] = list;
    setColorsArr(allColorsArrData);
  };

  // Handle Editing a Color
  const handleeditcolor = (color, colorIndex) => {
    // Set loading state
    setColorsArr((prevColors) =>
      prevColors.map((item, index) =>
        index === colorIndex ? { ...item, loading: true } : item
      )
    );

    // Serialize images in the current order
    const imagesString = color.images.map((img) => img.link).join('***');

    const data_send = {
      product_id: ProdDaWioutCol.product_id,
      color_id: color.id, // Ensure you send the color ID
      images: imagesString,
      color_code: color.color_code,
      color: color.color,
      color_ar: color.color_ar,
    };

    console.log('Sending data to backend:', data_send); // Debugging

    axios
      .post("https://api.manjam.shop/v4/color/edit", data_send)
      .then((res) => {
        if (res.data.status === 1) {
          toast.success(res.data.message);
          getproductbyid(); // Refresh the data
        } else if (res.data.status === 0) {
          toast.error(res.data.message);
        } else {
          toast.error(
            language === "ar" ? "حدث خطأ ما" : "Something went wrong"
          );
        }
      })
      .catch((err) => {
        console.error('Error editing color:', err);
        toast.error(
          language === "ar" ? "حدث خطأ ما" : "Something went wrong"
        );
      })
      .finally(() => {
        // Remove loading state
        setColorsArr((prevColors) =>
          prevColors.map((item, index) =>
            index === colorIndex ? { ...item, loading: false } : item
          )
        );
      });
  };

  // Handle Editing Product Data
  const handleeditproductdata = () => {
    setProdDaWioutCol({ ...ProdDaWioutCol, loading: true });
    const data_send = {
      product_id: ProdDaWioutCol.product_id,
      title: ProdDaWioutCol.title,
      description: ProdDaWioutCol.description,
      model_number: ProdDaWioutCol.model_number,
      title_ar: ProdDaWioutCol.title_ar,
      description_ar: ProdDaWioutCol.description_ar,
      weight: ProdDaWioutCol.weight,
      height: ProdDaWioutCol.height,
      breadth: ProdDaWioutCol.breadth,
      length: ProdDaWioutCol.length,
      msrp: 0,
      UPC_EAN: ProdDaWioutCol.UPC_EAN,
    };
    axios
      .post("https://api.manjam.shop/v4/product/edit_product", data_send)
      .then((res) => {
        if (res.data.status === 1) {
          toast.success(res.data.message);
          getproductbyid();
        } else if (res.data.status === 0) {
          toast.error(res.data.message);
        } else {
          toast.error(
            language === "ar" ? "حدث خطأ ما" : "Something went wrong"
          );
        }
      })
      .catch((err) => {
        console.error("Error editing product data:", err);
        toast.error(
          language === "ar" ? "حدث خطأ ما" : "Something went wrong"
        );
      })
      .finally(() => {
        setProdDaWioutCol({ ...ProdDaWioutCol, loading: false });
      });
  };

  // Function to move images within a color's image array
  const moveImage = (colorIndex, dragIndex, hoverIndex) => {
    const updatedColors = [...ColorsArr];
    const color = updatedColors[colorIndex];
    const draggedImage = color.images[dragIndex];
    color.images.splice(dragIndex, 1);
    color.images.splice(hoverIndex, 0, draggedImage);
    setColorsArr(updatedColors);
  };

  // Handle Deleting a Color Image
  const handledeletecolorimg = (itimg, colorIndex, indimg) => {
    let allcolors = [...ColorsArr];
    let allcolorsimgs = [...allcolors[colorIndex].images];
    let pushedcolors = allcolorsimgs.filter((item) => item.id !== itimg.id);
    allcolors[colorIndex].images = pushedcolors;
    setColorsArr(allcolors);
    setcolorDeletedImages(itimg);
  };

  // Handle Editing a Property
  const handleeditprop = (itProp, indProp, index) => {
    console.log(itProp);
    let allcolors = [...ColorsArr];
    console.log(allcolors);

    for (let color of allcolors) {
      for (let prop of color.props) {
        if (indProp === color.props.indexOf(prop)) {
          prop.loading = true;
        }
      }
    }
    setColorsArr(allcolors);
    const data_send = {
      prop_id: itProp.id,
      label: itProp.label,
      label_ar: itProp.label_ar,
    };
    axios
      .post("https://api.manjam.shop/v4/color_props/edit", data_send)
      .then((res) => {
        if (res.data.status === 1) {
          getproductbyid();
          toast.success(res.data.message);
        } else if (res.data.status === 0) {
          toast.error(res.data.message);
        } else {
          toast.error(
            language === "ar" ? "حدث خطأ ما" : "Something went wrong"
          );
        }
      })
      .catch((err) => {
        console.error("Error editing property:", err);
        toast.error(
          language === "ar" ? "حدث خطأ ما" : "Something went wrong"
        );
      })
      .finally(() => {
        let allcolors = [...ColorsArr];
        console.log(allcolors);

        for (let color of allcolors) {
          for (let prop of color.props) {
            if (indProp === color.props.indexOf(prop)) {
              prop.loading = false;
            }
          }
        }
        setColorsArr(allcolors);
      });
  };

  // Fetch Data on Component Mount
  useEffect(() => {
    getproductbyid();
    eqdata();
    getcategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="h-container con-h">
      <DndProvider backend={HTML5Backend}>
        <DefaultLayout
          children={
            <div className="childs products_table">
              <ContentNav
                head={
                  language === "ar"
                    ? "تعديل المنتج وخصائصه"
                    : "Edit Product And Its Features"
                }
              />
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleeditproductdata();
                }}
                className="formaddproduct"
              >
                {/* Product Name in English */}
                <label htmlFor="">
                  {language === "ar"
                    ? "إسم المنتج بالإنجليزيه"
                    : "Product Name In English"}
                </label>
                <input
                  value={ProdDaWioutCol.title}
                  onChange={(e) => {
                    setProdDaWioutCol({
                      ...ProdDaWioutCol,
                      title: e.target.value,
                    });
                  }}
                  type="text"
                  placeholder={
                    language === "ar"
                      ? "إسم المنتج بالإنجليزيه"
                      : "Product Name In English"
                  }
                />

                {/* Product Name in Arabic */}
                <label htmlFor="">
                  {language === "ar"
                    ? "إسم المنتج بالعربيه"
                    : "Product Name In Arabic"}
                </label>
                <input
                  value={ProdDaWioutCol.title_ar}
                  onChange={(e) => {
                    setProdDaWioutCol({
                      ...ProdDaWioutCol,
                      title_ar: e.target.value,
                    });
                  }}
                  type="text"
                  placeholder={
                    language === "ar"
                      ? "إسم المنتج بالعربيه"
                      : "Product Name In Arabic"
                  }
                />

                {/* Product Description in English */}
                <label htmlFor="">
                  {language === "ar"
                    ? "وصف المنتج بالإنجليزيه"
                    : "Product Description In English"}
                </label>
                <ReactQuill
                  value={ProdDaWioutCol.description}
                  onChange={(value) => {
                    setProdDaWioutCol({
                      ...ProdDaWioutCol,
                      description: value,
                    });
                  }}
                  placeholder={
                    language === "ar"
                      ? "وصف المنتج بالإنجليزيه"
                      : "Product Description In English"
                  }
                />

                {/* Product Description in Arabic */}
                <label htmlFor="">
                  {language === "ar"
                    ? "وصف المنتج بالعربيه"
                    : "Product Description In Arabic"}
                </label>
                <ReactQuill
                  value={ProdDaWioutCol.description_ar}
                  onChange={(value) => {
                    setProdDaWioutCol({
                      ...ProdDaWioutCol,
                      description_ar: value,
                    });
                  }}
                  placeholder={
                    language === "ar"
                      ? "وصف المنتج بالعربيه"
                      : "Product Description In Arabic"
                  }
                />

                {/* Brand Name */}
                <label htmlFor="">
                  {language === "ar" ? "الماركة" : "Brand Name"}
                </label>
                <input
                  value={ProdDaWioutCol.model_number}
                  onChange={(e) => {
                    setProdDaWioutCol({
                      ...ProdDaWioutCol,
                      model_number: e.target.value,
                    });
                  }}
                  type="text"
                  placeholder={
                    language === "ar" ? "رقم النموذج" : "Model Number"
                  }
                />

                {/* UPC/EAN */}
                <label htmlFor="">
                  {language === "ar" ? "UPC/EAN" : "UPC/EAN"}
                </label>
                <input
                  value={ProdDaWioutCol?.UPC_EAN}
                  onChange={(e) => {
                    setProdDaWioutCol({
                      ...ProdDaWioutCol,
                      UPC_EAN: e.target.value,
                    });
                  }}
                  type="text"
                  placeholder={
                    language === "ar" ? "UPC/EAN" : "UPC/EAN"
                  }
                />

                {/* Package Details */}
                <label>Package Details</label>
                <div className="packDetails">
                  <div className="input">
                    <label htmlFor="weight">Weight (In KG)</label>
                    <input
                      type="text"
                      min={1}
                      value={ProdDaWioutCol.weight}
                      onChange={(e) => {
                        setProdDaWioutCol({
                          ...ProdDaWioutCol,
                          weight: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="input">
                    <label htmlFor="Length">Length (In CM)</label>
                    <input
                      type="text"
                      min={1}
                      value={ProdDaWioutCol.length}
                      onChange={(e) => {
                        setProdDaWioutCol({
                          ...ProdDaWioutCol,
                          length: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="input">
                    <label htmlFor="Breadth">Breadth (In CM)</label>
                    <input
                      type="text"
                      min={1}
                      value={ProdDaWioutCol.breadth}
                      onChange={(e) => {
                        setProdDaWioutCol({
                          ...ProdDaWioutCol,
                          breadth: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="input">
                    <label htmlFor="Height">Height (In CM)</label>
                    <input
                      type="text"
                      min={1}
                      value={ProdDaWioutCol.height}
                      onChange={(e) => {
                        setProdDaWioutCol({
                          ...ProdDaWioutCol,
                          height: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>

                {/* Submit Button */}
                {ProdDaWioutCol.loading ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "10px",
                    }}
                  >
                    <Loader />
                  </div>
                ) : (
                  <button type="submit">
                    {language === "ar" ? "تعديل المنتج" : "Edit the Product"}
                  </button>
                )}
              </form>

              {/* Iterate through Colors */}
              {ColorsArr?.map((item, index) => {
                return (
                  <div key={index}>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        if (item.new) {
                          handleaddcolor(item);
                        } else {
                          handleeditcolor(item, index);
                        }
                      }}
                      className="from_Shipping"
                    >
                      {/* Images Label */}
                      <label htmlFor="">
                        {language === "ar" ? "الصور" : "Images"}
                      </label>

                      {/* Images with Drag-and-Drop */}
                      <div className="color_images">
                        {item?.images?.map((itimg, indimg) => {
                          return (
                            <DraggableImage
                              key={itimg.id || itimg.link} // Use unique link as key
                              img={itimg}
                              index={indimg}
                              moveImage={moveImage}
                              colorIndex={index}
                              indimg={indimg}
                            />
                          );
                        })}
                      </div>

                      {/* Image Upload Section */}
                      <div className="updiv">
                        <input
                          onChange={(e) => {
                            setimage(e.target.files[0]);
                          }}
                          type="file"
                        />
                        {imageloading2 ? (
                          <Icon icon="eos-icons:loading" />
                        ) : (
                          <button
                            type="button"
                            onClick={() => {
                              handleuploadaftereditcolors(index);
                            }}
                          >
                            {language === "ar" ? "رفع الصورة" : "Upload Image"}
                          </button>
                        )}
                      </div>

                      {/* Choose Color Dropdown */}
                      <label htmlFor="">
                        {language === "ar" ? "اختر اللون" : "Choose Color"}
                      </label>
                      <select
                        value={item.color_code}
                        onChange={(e) => {
                          const selectedColor = colors?.find(
                            (colorItem) =>
                              colorItem.color_code === e.target.value
                          );

                          if (selectedColor) {
                            // Update color_code, color, and color_ar
                            handlechangecolor(
                              {
                                target: {
                                  name: "color_code",
                                  value: selectedColor.color_code,
                                },
                              },
                              index
                            );
                            handlechangecolor(
                              {
                                target: {
                                  name: "color",
                                  value: selectedColor.color_en,
                                },
                              },
                              index
                            );
                            handlechangecolor(
                              {
                                target: {
                                  name: "color_ar",
                                  value: selectedColor.color_ar,
                                },
                              },
                              index
                            );
                          }
                        }}
                      >
                        <option value="">
                          {language === "ar" ? "اختر" : "Select"}
                        </option>
                        {colors?.map((colorItem) => (
                          <option
                            key={colorItem.color_code}
                            value={colorItem.color_code}
                          >
                            {language === "ar"
                              ? colorItem.color_ar
                              : colorItem.color_en}
                          </option>
                        ))}
                      </select>

                      {/* Submit Button for Color Form */}
                      {item.loading ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: "10px",
                          }}
                        >
                          <Loader />
                        </div>
                      ) : item.new === true ? (
                        <button type="submit">
                          {language === "ar" ? "إضافه" : "Add"}
                        </button>
                      ) : (
                        <button type="submit">
                          {language === "ar" ? "تعديل" : "Edit"}
                        </button>
                      )}
                    </form>

                    {/* Properties Section */}
                    {item.new ? null : (
                      <div
                        style={{
                          margin: "20px 0px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        {/* Optional: Add functionality to add new properties */}
                      </div>
                    )}

                    {/* Iterate through Properties */}
                    {item?.props?.map((itProp, indProp) => {
                      return (
                        <div key={itProp.id || indProp}>
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                            }}
                            className="from_Shipping colform"
                            action=""
                          >
                            {/* Color Indicator */}
                            <div
                              style={{
                                width: "20px",
                                height: "20px",
                                borderRadius: "50%",
                                backgroundColor: `${item.color_code}`,
                              }}
                            ></div>

                            {/* Property Label in English */}
                            <label htmlFor="">
                              {language === "ar"
                                ? "إسم الخاصيه بالإنجليزيه"
                                : "Attribute In English"}
                            </label>
                            <input
                              value={itProp.label}
                              name="label"
                              onChange={(e) => {
                                handlechagepropform(
                                  e,
                                  item,
                                  index,
                                  itProp,
                                  indProp
                                );
                              }}
                              type="text"
                            />

                            {/* Property Label in Arabic */}
                            <label htmlFor="">
                              {language === "ar"
                                ? "إسم الخاصيه بالعربيه"
                                : "Attribute In Arabic"}
                            </label>
                            <input
                              value={itProp.label_ar}
                              name="label_ar"
                              onChange={(e) => {
                                handlechagepropform(
                                  e,
                                  item,
                                  index,
                                  itProp,
                                  indProp
                                );
                              }}
                              type="text"
                            />

                            {/* Add or Edit Property Button */}
                            {itProp.new ? (
                              itProp.loading ? (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    margin: "10px 0px",
                                  }}
                                >
                                  <Loader />
                                </div>
                              ) : (
                                <button
                                  type="button"
                                  onClick={() => {
                                    handleaddproperity(itProp);
                                  }}
                                >
                                  {language === "ar" ? "إضافه" : "Add"}
                                </button>
                              )
                            ) : itProp.loading ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  margin: "10px 0px",
                                }}
                              >
                                <Loader />
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  gap: "4px",
                                  flexWrap: "wrap",
                                }}
                              >
                                <button
                                  type="button"
                                  onClick={() => {
                                    if (itProp.new) {
                                      handleaddproperity(itProp);
                                    } else {
                                      handleeditprop(itProp, indProp, index);
                                    }
                                  }}
                                >
                                  {language === "ar" ? "تعديل" : "Edit"}
                                </button>
                              </div>
                            )}
                          </form>

                          {/* Additional Styling or Components */}
                          {itProp.new ? null : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                margin: "20px 0px",
                              }}
                            ></div>
                          )}

                          {/* Iterate through Property Values */}
                          {itProp?.values?.map((itPropValue, indPropValue) => {
                            return (
                              <form
                                key={itPropValue.id || indPropValue}
                                onSubmit={(e) => {
                                  e.preventDefault();
                                }}
                                style={{ margin: "20px 0px" }}
                                className="from_Shipping"
                              >
                                {/* Property Value Label in English */}
                                <label htmlFor="">
                                  {language === "ar"
                                    ? "قيمة الخاصيه بالإنجليزيه"
                                    : "Attribute Value In English"}
                                </label>
                                <input
                                  value={itPropValue.label}
                                  name="label"
                                  onChange={(e) => {
                                    handlechangeprpvalue(
                                      e,
                                      index,
                                      indProp,
                                      indPropValue
                                    );
                                  }}
                                  type="text"
                                />

                                {/* Property Value Label in Arabic */}
                                <label htmlFor="">
                                  {language === "ar"
                                    ? "قيمة الخاصيه بالعربيه"
                                    : "Attribute Value In Arabic"}
                                </label>
                                <input
                                  value={itPropValue.label_ar}
                                  name="label_ar"
                                  onChange={(e) => {
                                    handlechangeprpvalue(
                                      e,
                                      index,
                                      indProp,
                                      indPropValue
                                    );
                                  }}
                                  type="text"
                                />

                                {/* Add or Edit Property Value Button */}
                                {itPropValue.new ? (
                                  itPropValue.loading ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        margin: "10px 0px",
                                      }}
                                    >
                                      <Loader />
                                    </div>
                                  ) : (
                                    <button
                                      type="button"
                                      onClick={() => {
                                        handleaddPropsValue(itPropValue);
                                      }}
                                    >
                                      {language === "ar" ? "إضافه" : "Add"}
                                    </button>
                                  )
                                ) : itPropValue.loading ? (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      margin: "10px 0px",
                                    }}
                                  >
                                    <Loader />
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      gap: "4px",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      onClick={() => {
                                        if (itPropValue.new) {
                                          handleaddPropsValue(itPropValue);
                                        } else {
                                          handleeditpropvalue(itPropValue);
                                        }
                                      }}
                                    >
                                      {language === "ar" ? "تعديل" : "Edit"}
                                    </button>
                                  </div>
                                )}
                              </form>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          }
        />
      </DndProvider>
    </div>
  );
};

export default EditProduct;
