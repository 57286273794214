import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layout/defaultlayout";
import ContentNav from "../../datanavcontent";
import "./style.css";
import Switches from "../../components/switches";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Quill theme styling
import {
  add_category,
  add_class,
  add_contact,
  add_offer,
  add_user,
  address_input,
  categories_input,
  changeFunction,
  choices,
  chooseDep,
  classification_input,
  contact_input,
  nameMont,
  offer_input,
  user_input,
} from "./functions";
import Form from "../../components/form";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import { Icon } from "@iconify/react";
import { Loader } from "rsuite";
import { AiOutlinePlus } from "react-icons/ai";
import { colors } from "./colors";
import { Modal } from "antd";
import ProductPreview from "./productPreview";
import { useNavigate } from "react-router-dom";
function Addition() {
  const language = useSelector((state) => state.language.lang);
  const [choice, setChoice] = useState("product");
  const [categoryId, setCategoryId] = useState(false);
  const [image, setimage] = useState([]);
  const [categories, setcategories] = useState([]);
  const [category_id, setcategory_id] = useState([]);
  const [showcorlorform, setshowcorlorform] = useState(false);

  const [ColorsPropeity, setColorsPropeity] = useState([]);

  const [colorproperty, setcolorproperty] = useState({
    product_id: "",
    images: "",
    // price:'',
    // new_price:'',
    // discount:'',
    available_quantity: "",
    color_code: "",
    color: "",
    color_ar: "",
  });

  const [addedproduct, setaddedproduct] = useState({
    category_id: "",
    title: "",
    title_ar: "",
    description: "",
    grade: "new",
    description_ar: "",
    model_number: "",
    producing_company: "",
    // will_av_after:'',
    // will_av_for:'',
    conditions: "",
    conditions_ar: "",
    isReturned: "1",
    return_period: "0",
    store: "ksa",
  });
  const [addedcategory, setaddedcategory] = useState({
    title: "",
    title_ar: "",
    image_url: "",
  });
  const [productloading, setproductloading] = useState(false);
  const [preview, setPreview] = useState(null);
  const [images, setimages] = useState([]);
  const [imagecategory, setimagecategory] = useState(null);
  const [imageloading, setimageloading] = useState(false);
  const [imageloading2, setimageloading2] = useState(false);
  const [addloading, setaddloading] = useState(false);
  const [AddedProductId, setAddedProductId] = useState("");

  const [ColorsArr, setColorsArr] = useState([
    {
      product_id: "",
      images: "",
      available_quantity: "",
      color_code: "",
      color: "",
      color_ar: "",
      ColorProperities: [
        {
          label: "",
          label_ar: "",
          Properity_values: [{ label: "", label_ar: "" }],
        },
      ],
      colloading: false,
    },
  ]);

  const [addobj, setaddobj] = useState({
    label: "",
    label_ar: "",
    // stoke:'',
  });
  const [addobjpropval, setaddobjpropval] = useState({
    label: "",
    label_ar: "",
    // plus_price:'',
    // stock:'',
  });
  const [bannerdata, setbannerdata] = useState({
    title: "",
    title_ar: "",
    text: "",
    text_ar: "",
    imageLink: "",
    link: "",
  });
  const getcategories = async () => {
    await axios
      .get("https://api.manjam.shop/v4/category/getAll?type=admin")
      .then((res) => {
        // console.log(res.data.message)
        setcategories(res.data.message);
        setcategory_id(res?.data.message[0]?.id);
        // setaddedproduct({...addedproduct,category_id})
        setaddedproduct({
          ...addedproduct,
          category_id: res.data.message[0]?.id,
        });
      });
  };
  const navigate = useNavigate();
  const handleuploadaftereditcolors = (i) => {
    setimageloading2(true);
    const formdata = new FormData();
    formdata.append("image", image);
    axios
      .post("https://image-uploader-ochre.vercel.app/image/upload", formdata)
      .then((res) => {
        // console.log(res)
        if (res.data.imgUrl != "") {
          toast.success("has uploaded");
          setimages([...images, res.data.imgUrl]);
          let list = [...ColorsArr];
          if (list[i]["images"] == "") {
            list[i]["images"] += res.data.imgUrl;
            // console.log("one");
          } else {
            list[i]["images"] += "***";
            list[i]["images"] += res.data.imgUrl;
            // console.log("two");
            // console.log(list[i]['images']);
          }
          // console.log(list)
          setColorsArr(list);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setimageloading2(false);
      });
  };

  const uploadImage = async (image) => {
    const formdata = new FormData();
    formdata.append("file", image);
    formdata.append("upload_preset", "ghofdcab");
    try {
      const res = await axios.post(
        "https://api.cloudinary.com/v1_1/duovxefh6/upload",
        formdata
      );
      console.log("res", res);
      return res.data.secure_url;
    } catch (err) {
      return "";
    }
  };
  const addProduct = async () => {
    setproductloading(true);
    let added = false;
    let colorImages = [];
    if (image?.length) {
      const xx = [];
      const uploadPromises = Array.from(image)
        ?.sort((a, b) => parseInt(a?.index) - parseInt(b?.index))
        console.log(uploadPromises)
        for (const item of uploadPromises){
          try {
            const uploaded = await uploadImage(item?.file)
            colorImages?.push(uploaded)
          }catch(err){}
        }

      // colorImages = await Promise.all(uploadPromises);
    }
    await Promise.all(
      ColorsArr[0].ColorProperities?.map(async (item) => {
        // console.log("ColorsArr", ColorsArr);
        const data_send = {
          ...addedproduct,
          msrp: 3,
        };
        delete data_send?.imagesUrls;
        // alert("asd")
        await axios
          .post("https://api.manjam.shop/v4/product/add_product", data_send)
          .then(async (res) => {
            const data_send = {
              product_id: res.data.message,
              images: colorImages?.join("***"),
              color: ColorsArr[0]?.color,
              color_ar: ColorsArr[0]?.color_ar,
              color_code: ColorsArr[0]?.color_code,
            };
            if (res.data.message) {
              added = true;
            }
            await axios
              .post("https://api.manjam.shop/v4/color/add", data_send)
              .then(async (res) => {
                const data_send = {
                  color_id: res.data.message,
                  label: item.label,
                  label_ar: item.label_ar,
                };
                axios
                  .post("https://api.manjam.shop/v4/color_props/add", data_send)
                  .then((res) => {
                    const data_send = {
                      label: item?.Properity_values[0]?.label,
                      label_ar: item?.Properity_values[0]?.label_ar,
                      prop_id: res?.data?.message,
                    };
                    axios
                      .post(
                        "https://api.manjam.shop/v4/color_props/add_value",
                        data_send
                      )
                      .then((res) => {});
                  });
                // await handleaddproperity()
                // await handleaddproperityValue()
              })
              .catch((err) => console.log(err))
              .finally(() => {});
            if (res.data.status === 1) {
              toast.success(res.data.message);
              setcolorproperty({
                ...colorproperty,
                product_id: res.data.message,
              });
              setshowcorlorform(true);
              setAddedProductId(res.data.message);
            } else if (res.data.status === 0) {
              toast.error(res.data.message);
            } else {
              toast.error("حدث خطأ ما");
            }
          })
          .catch((err) => console.log(err))
          .finally(() => {});
      })
    );
    // window.location.href = "/products";
    if (added) {
      navigate("/products"); // Redirect to the products page
    } else {
      toast.error("An error occurred while adding the product");
    }
    setproductloading(false);
  };

  const handlechangecolor = (e, i) => {
    // console.log(e);
    // console.log(i);
    const list = [...ColorsArr];
    list[i][e.name] = e.value;
    setColorsArr(list);
  };

  useEffect(() => {
    getcategories();
  }, []);
  useEffect(() => {
    // console.log(choice);
  }, [choice]);
  const handleuploadafteredit = () => {
    setimageloading(true);
    const formdata = new FormData();
    formdata.append("image", image);
    axios
      .post("https://image-uploader-ochre.vercel.app/image/upload", formdata)
      .then((res) => {
        // console.log(res)
        if (res.data.imgUrl != "") {
          toast.success("has uploaded");
          setbannerdata({ ...bannerdata, imageLink: res.data.imgUrl });
          // setorderData({...orderData,image:res.data.imgUrl});
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setimageloading(false);
      });
  };
  const handleaddbanner = () => {
    setaddloading(true);
    const data_send = {
      ...bannerdata,
    };
    // console.log(data_send)
    axios
      .post("https://api.manjam.shop/v4/banner/add_banner", data_send)
      .then((res) => {
        // console.log(res)
        if (res.data.status == 1) {
          toast.success(res.data.message);
        } else if (res.data.status == 0) {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ? "حدذ خطأ ما" : "Something Went Error");
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setaddloading(false);
      });
  };
  const handleuploadimage = () => {
    setimageloading(true);
    const formdata = new FormData();
    formdata.append("image", image);
    axios
      .post("https://image-uploader-ochre.vercel.app/image/upload", formdata)
      .then((res) => {
        // console.log(res.data)
        if (res.data.imgUrl != "") {
          toast.success("has uploaded");
          setaddedcategory({ ...addedcategory, image_url: res.data.imgUrl });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setimageloading(false);
      });
  };
  const handleaddcategory = () => {
    const data_send = {
      ...addedcategory,
    };
    // console.log(data_send);
    axios
      .post("https://api.manjam.shop/v4/category/add", data_send)
      .then((res) => {
        if (res.data.status == 1) {
          toast.success(res.data.message);
          window.location.reload();
        } else if (res.data.status == 0) {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "Something Went Error");
        }
      })
      .catch((err) => console.log(err));
  };

  const handleaddcolor = (item, i) => {
    // console.log(item)
    if (item.color == "" || item.color_ar == "" || item.color_code == "") {
      toast.warn(language == "ar" ? "أكمل البيانات" : "Complete Data");
      return;
    }
    let allColorsArr = [...ColorsArr];
    setColorsArr(
      allColorsArr.map((it, index) => {
        if (index * 1 == i * 1) {
          return { ...it, colloading: true };
        } else return { ...it };
      })
    );
  };

  const handlechagepropform = (e, i, index) => {
    // console.log(e);
    // console.log(i);
    let list = [...ColorsArr];
    let list3 = list[index]?.ColorProperities;
    console.log(e.target, i, index);
    list3[i][e.target.name] = e.target.value;
    // console.log(list)
    setColorsPropeity(list);
    let allColorsData = [...ColorsArr];
    // console.log(allColorsData)
    let list2 = [...ColorsArr[index].ColorProperities];
    list2[i][e.target.name] = e.target.value;
    // console.log(list2)
    allColorsData[index].ColorProperities = list2;
    setColorsArr(allColorsData);
    // console.log(allColorsData)
    // let pushedarr=[];
    // for(let k=0;k<allColorsData?.ColorProperities?.length;k++){
    //   if(i+1==list2[i]){

    //   }
    // }
  };

  const handleaddproperity = (it, item, index, ind) => {
    if (it.label == "" || it.label_ar == "") {
      toast.warn(language == "ar" ? "أكمل البيانات" : "Complete Data");
      return;
    }
    let allColorsArr = [...ColorsArr];
    let list2 = [...ColorsArr[index].ColorProperities];
    let list3 = list2.map((ite, inde) => {
      if (ind * 1 == inde * 1) {
        return { ...ite, proploading: true };
      } else return { ...ite };
    });
    allColorsArr[index].ColorProperities = list3;
    setColorsArr(allColorsArr);
    const data_send = {
      color_id: it.color_id,
      label: it.label,
      label_ar: it.label_ar,
    };
    axios
      .post("https://api.manjam.shop/v4/color_props/add", data_send)
      .then((res) => {
        if (res.data.status == 1) {
          toast.success(
            language == "ar" ? "تمت الإضافه بنجاح" : "Added Successfully"
          );
          let allColorsArr = [...ColorsArr];
          let list2 = [...ColorsArr[index].ColorProperities];
          list2[ind]["Properity_values"] = [
            {
              label: "",
              label_ar: "",
              // plus_price:'',
              // stock:'',
              prop_id: res.data.message,
              propvalloading: false,
            },
          ];
          allColorsArr[index].ColorProperities = list2;
          setColorsArr(allColorsArr);
        } else if (res.data.status == 0) {
          toast.error(language == "ar" ? "لم تتم الإضافه" : "Not Added");
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "Something Went Error");
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        let allColorsArr = [...ColorsArr];
        let list2 = [...ColorsArr[index].ColorProperities];
        let list3 = list2.map((ite, inde) => {
          if (ind * 1 == inde * 1) {
            return { ...ite, proploading: false };
          } else return { ...ite };
        });
        allColorsArr[index].ColorProperities = list3;
        setColorsArr(allColorsArr);
      });
  };

  const handlechangeprpvalue = (e, item, index, it, ind, itprop, indpro) => {
    let allColorsArrData = [...ColorsArr];
    let list = ColorsArr[index].ColorProperities[ind].Properity_values[indpro];
    // console.log(list)
    list[e.target.name] = e.target.value;
    allColorsArrData[index].ColorProperities[ind].Properity_values[indpro] =
      list;
    setColorsArr(allColorsArrData);
  };

  const handleaddPropsValue = (item, index, it, ind, itprop, indpro) => {
    if (itprop.label == "" || itprop.label_ar == "") {
      toast.warn(language == "ar" ? "أكمل البيانات" : "Compelete Data");
      return;
    }
    let allcolors = [...ColorsArr];
    // let listProps=allcolors[index];
    let list = allcolors[index].ColorProperities[ind].Properity_values[indpro];
    list.propvalloading = true;

    for (let i = 0; i < allcolors?.length; i++) {
      let allprops = [...allcolors[i].ColorProperities];
      for (let j = 0; j < allprops?.length; j++) {
        let allpropvalues = [...allprops[j].Properity_values];
        for (let k = 0; k < allpropvalues?.length; k++) {
          let objval = { ...allpropvalues[k] };
          if (indpro * 1 == k * 1) {
            // objval.propvalloading=true;
            allcolors[index].ColorProperities[ind].Properity_values[
              indpro
            ].propvalloading = true;
          }
        }
        // allprops[j].Properity_values=allpropvalues;
      }
    }

    // for(let i=0;i<allcolors?.length;i++){
    //   let allprops=[...allcolors[i].ColorProperities];
    //   for(let j=0;j<allprops?.length;j++){
    //     let allpropvalues=[...allprops[j].Properity_values];
    //     for(let k=0;k<allpropvalues?.length;k++){
    //       let objval={...allpropvalues[k]};
    //       if(indpro*1==k*1){
    //         objval.propvalloading=true;
    //       }
    //     }
    //     allprops[j].Properity_values=allpropvalues;
    //   }
    //   allcolors[i].ColorProperities=allprops;
    // }
    setColorsArr(allcolors);

    const data_send = {
      ...itprop,
    };
    axios
      .post("https://api.manjam.shop/v4/color_props/add_value", data_send)
      .then((res) => {
        if (res.data.status == 1) {
          toast.success(res.data.message);
        } else if (res.data.status == 0) {
          toast.error(res.data.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "Something Went Error");
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        let allcolors = [...ColorsArr];
        for (let i = 0; i < allcolors?.length; i++) {
          let allprops = [...allcolors[i].ColorProperities];
          for (let j = 0; j < allprops?.length; j++) {
            let allpropvalues = [...allprops[j].Properity_values];
            for (let k = 0; k < allpropvalues?.length; k++) {
              let objval = { ...allpropvalues[k] };
              if (indpro * 1 == k * 1) {
                // objval.propvalloading=true;
                allcolors[index].ColorProperities[ind].Properity_values[
                  indpro
                ].propvalloading = false;
              }
            }
            // allprops[j].Properity_values=allpropvalues;
          }
        }
        setColorsArr(allcolors);
      });
  };

  return (
    <div className="h-container con-h">
      <DefaultLayout
        children={
          <div className="childs">
            <ContentNav head={language == "ar" ? "إضافة" : "Add"} />
            <div className="table-t common-cl">
              <Switches
                switches={choices}
                choice={choice}
                chooseFunction={chooseDep}
                setChoice={setChoice}
              />

              {choice == "product" ? (
                <>
                  {/* <Form
                    inputs={classification_input((id) => {
                      setCategoryId(id);
                    }, categoryId)}
                    submitFunction={add_class}
                  /> */}
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      addProduct();
                    }}
                    className="formaddproduct"
                  >
                    <label htmlFor="">
                      {language == "ar" ? "الفئه" : "Category"}
                    </label>
                    <select
                      value={addedproduct.category_id}
                      onChange={(e) => {
                        setcategory_id(e.target.value);
                        setaddedproduct({
                          ...addedproduct,
                          category_id: e.target.value,
                        });
                      }}
                    >
                      {/* {console.log(categories.length)} */}
                      {categories &&
                      categories.length &&
                      categories != "no category"
                        ? categories?.map((it, index) => {
                            return (
                              <option value={it.id} key={index}>
                                {it.title}
                              </option>
                            );
                          })
                        : null}
                    </select>
                    <label htmlFor="">
                      {language == "ar"
                        ? "إسم المنتج بالإنجليزيه"
                        : "Product Name In English"}
                    </label>
                    <input
                      onChange={(e) => {
                        setaddedproduct({
                          ...addedproduct,
                          title: e.target.value,
                        });
                      }}
                      type="text"
                      placeholder={
                        language == "ar"
                          ? "إسم المنتج بالإنجليزيه"
                          : "Product Name In English"
                      }
                    />
                    <label htmlFor="">
                      {language == "ar"
                        ? "إسم المنتج بالعربيه"
                        : "Product Name In Arabic"}
                    </label>
                    <input
                      onChange={(e) => {
                        setaddedproduct({
                          ...addedproduct,
                          title_ar: e.target.value,
                        });
                      }}
                      type="text"
                      placeholder={
                        language == "ar"
                          ? "إسم المنتج بالعربيه"
                          : "Product Name In Arabic"
                      }
                    />
                    <label htmlFor="">
                      {language == "ar" ? "حالة المنتج" : "Grade"}
                    </label>
                    <select
                      onChange={(e) => {
                        setaddedproduct({
                          ...addedproduct,
                          grade: e.target.value,
                        });
                      }}
                      value={addedproduct.grade}
                    >
                      <option value="used">
                        {language == "ar" ? "مستخدم" : "used"}
                      </option>
                      <option value="new">
                        {language == "ar" ? "جديد" : "new"}
                      </option>
                    </select>
                    {/* <input onChange={(e)=>{
                    setaddedproduct({...addedproduct,grade:e.target.value})
                  }} type="text" placeholder={language=='ar'?"حالة المنتج":"grade"}/> */}
                    <>
                      {/* English Product Description */}
                      <label htmlFor="">
                        {language === "ar"
                          ? "وصف المنتج بالإنجليزيه"
                          : "Product Description In English"}
                      </label>
                      <ReactQuill
                        theme="snow"
                        modules={{
                          toolbar: [
                            ["bold", "italic", "underline", "strike"],
                            ["blockquote", "code-block"],
                            [{ header: 1 }, { header: 2 }],
                            [{ list: "ordered" }, { list: "bullet" }],
                            [{ script: "sub" }, { script: "super" }],
                            [{ indent: "-1" }, { indent: "+1" }],
                            [{ direction: "rtl" }],
                            ["link", "image"],
                          ],
                        }}
                        value={addedproduct.description || ""}
                        onChange={(content) =>
                          setaddedproduct((prev) => ({
                            ...prev,
                            description: content,
                          }))
                        }
                        placeholder={
                          language === "ar"
                            ? "وصف المنتج بالإنجليزيه"
                            : "Product Description In English"
                        }
                      />

                      {/* Arabic Product Description */}
                      <label htmlFor="">
                        {language === "ar"
                          ? "وصف المنتج بالعربيه"
                          : "Product Description In Arabic"}
                      </label>
                      <ReactQuill
                        theme="snow"
                        modules={{
                          toolbar: [
                            ["bold", "italic", "underline", "strike"],
                            ["blockquote", "code-block"],
                            [{ header: 1 }, { header: 2 }],
                            [{ list: "ordered" }, { list: "bullet" }],
                            [{ script: "sub" }, { script: "super" }],
                            [{ indent: "-1" }, { indent: "+1" }],
                            [{ direction: "rtl" }],
                            ["link", "image"],
                          ],
                        }}
                        value={addedproduct.description_ar || ""}
                        onChange={(content) =>
                          setaddedproduct((prev) => ({
                            ...prev,
                            description_ar: content,
                          }))
                        }
                        placeholder={
                          language === "ar"
                            ? "وصف المنتج بالعربيه"
                            : "Product Description In Arabic"
                        }
                        dir={language === "ar" ? "rtl" : "ltr"} // Right-to-left for Arabic
                      />
                    </>
                    <label htmlFor="">
                      {language == "ar" ? "اسم الماركة" : "Brand Name"}
                    </label>
                    <input
                      onChange={(e) => {
                        setaddedproduct({
                          ...addedproduct,
                          model_number: e.target.value,
                        });
                      }}
                      type="text"
                      placeholder={
                        language == "ar" ? "اسم الماركة" : "Brand Name"
                      }
                    />
                    {/* <label htmlFor="price">MSRP</label>
                    <input
                      type="price"
                      min={1}
                      onChange={(e) => {
                        setaddedproduct({
                          ...addedproduct,
                          msrp: e.target.value,
                        });
                      }}
                    /> */}
                    <label htmlFor="">
                      {language == "ar" ? "UPC/EAN" : "UPC/EAN"}
                    </label>
                    <input
                      onChange={(e) => {
                        setaddedproduct({
                          ...addedproduct,
                          UPC_EAN: e.target.value,
                        });
                      }}
                      type="text"
                      placeholder={language == "ar" ? "UPC/EAN" : "UPC/EAN"}
                    />
                    {/* <label htmlFor="">
                      {language == "ar" ? "إسم الشركه" : "Comapny Name"}
                    </label>
                    <input
                      onChange={(e) => {
                        setaddedproduct({
                          ...addedproduct,
                          producing_company: e.target.value,
                        });
                      }}
                      type="text"
                      placeholder={
                        language == "ar" ? "إسم الشركه" : "Company Name"
                      }
                    /> */}
                    {/* <label htmlFor="">{language=='ar'?"سوف يتم توفيره بدايه من":"Will Avilable After"}</label>
                  <input onChange={(e)=>{
                    // console.log(e)
                    // console.log(moment(e.target.valueAsNumber).format("YYYY-mm-dd H:m:s"))
                    setaddedproduct({...addedproduct,will_av_after:e.target.value})
                  }} type="date" placeholder={language=='ar'?"سوف يتم توفيره بدايه من":"Will Avilable After"}/>

                  <label htmlFor="">{language=='ar'?"سوف يتم توفيره لوقت":"Will Avilable For"}</label>
                  <input min={addedproduct.will_av_after} onChange={(e)=>{
                    setaddedproduct({...addedproduct,will_av_for:e.target.value})
                  }} type="date" placeholder={language=='ar'?"سوف يتم توفيره لوقت":"Will Avilable For"}/> */}
                    {/* <label htmlFor="">
                      {language == "ar"
                        ? "الشروط بالانجليزيه"
                        : "Product Notes In English"}
                    </label>
                    <input
                      onChange={(e) => {
                        setaddedproduct({
                          ...addedproduct,
                          conditions: e.target.value,
                        });
                      }}
                      type="text"
                      placeholder={
                        language == "ar"
                          ? "الشروط بالإنجليزيه"
                          : "Product Notes In English"
                      }
                    />

                    <label htmlFor="">
                      {language == "ar"
                        ? "الشروط بالعربيه"
                        : "Product Notes In Arabic"}
                    </label>
                    <input
                      onChange={(e) => {
                        setaddedproduct({
                          ...addedproduct,
                          conditions_ar: e.target.value,
                        });
                      }}
                      type="text"
                      placeholder={
                        language == "ar"
                          ? "الشروط بالعربيه"
                          : "Product Notes In Arabic"
                      }
                    /> */}
                    {/* <label htmlFor="">
                      {language == "ar" ? "حالة الرجوع" : "Return Status"}
                    </label>
                    <select name="" id="">
                      <option
                        onClick={() => {
                          setaddedproduct({ ...addedproduct, isReturned: 1 });
                        }}
                        value="1"
                      >
                        {language == "ar" ? "ممكن" : "possible"}
                      </option>
                      <option
                        onClick={() => {
                          setaddedproduct({ ...addedproduct, isReturned: 0 });
                        }}
                        value="0"
                      >
                        {language == "ar" ? "غير ممكن" : "not possible"}
                      </option>
                    </select>
                    <label htmlFor="">
                      {language == "ar"
                        ? "المده المسموح فيها الارجاع"
                        : "Return Period"}
                    </label>
                    <input
                      disabled={addedproduct.isReturned == 0 ? true : false}
                      onChange={(e) => {
                        setaddedproduct({
                          ...addedproduct,
                          return_period: e.target.value,
                        });
                      }}
                      placeholder={
                        language == "ar"
                          ? "المده المسموح فيها الارجاع"
                          : "Return Period"
                      }
                      type="number"
                    />
                    <label htmlFor="">
                      {language == "ar" ? "المخزن المتوفر فيه" : "Store"}
                    </label>
                    <select name="" id="">
                      <option
                        onClick={(e) => {
                          setaddedproduct({ ...addedproduct, store: "ksa" });
                        }}
                        value=""
                      >
                        {language == "ar" ? "السعوديه" : "Saudi Arabia"}
                      </option>
                      <option
                        onClick={(e) => {
                          setaddedproduct({ ...addedproduct, store: "uae" });
                        }}
                        value=""
                      >
                        {language == "ar" ? "الامارات" : "The UAE"}
                      </option>
                    </select> */}
                    Package Details
                    <div className="packDetails">
                      <div className="input">
                        <label htmlFor="weight">weight (In KG)</label>
                        <input
                          type="text"
                          min={1}
                          onChange={(e) => {
                            setaddedproduct({
                              ...addedproduct,
                              weight: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="input">
                        <label htmlFor="Length">Length (In CM)</label>
                        <input
                          type="text"
                          min={1}
                          onChange={(e) => {
                            setaddedproduct({
                              ...addedproduct,
                              length: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="input">
                        <label htmlFor="Breadth">Breadth (In CM)</label>
                        <input
                          type="text"
                          min={1}
                          onChange={(e) => {
                            setaddedproduct({
                              ...addedproduct,
                              breadth: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="input">
                        <label htmlFor="Height">Height (In CM)</label>
                        <input
                          type="text"
                          min={1}
                          onChange={(e) => {
                            setaddedproduct({
                              ...addedproduct,
                              height: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    {ColorsArr?.map((item, index) => {
                      return (
                        <form
                          key={index}
                          onSubmit={(e) => {
                            e.preventDefault();
                            // handleedit();
                            handleaddcolor(item, index);
                            // setshowadd(false)
                          }}
                          className="from_Shipping"
                          action=""
                        >
                          <label htmlFor="">
                            {language == "ar" ? "الصور" : "Images"}
                          </label>
                          <div className="updiv">
                            <input
                              id="file-input"
                              multiple
                              onChange={(e) => {
                                const selectedFiles = Array.from(
                                  e.target.files
                                );

                                // Get the existing images in the state and add new ones with unique indices
                                setimage((prevFiles) => [
                                  ...prevFiles,
                                  ...selectedFiles.map((file, idx) => ({
                                    file,
                                    index: prevFiles.length + idx, // Assign a unique index
                                  })),
                                ]);

                                const galleryDiv =
                                  document.getElementById("image-gallery");

                                // Append new images to the gallery
                                let imagesURLS = [];
                                selectedFiles.forEach((file, index) => {
                                  const imgURL = URL.createObjectURL(file);
                                  imagesURLS.push(imgURL);

                                  // Create a new div for each image
                                  const imgDiv = document.createElement("div");
                                  imgDiv.style.display = "inline-block";
                                  imgDiv.style.margin = "10px";
                                  imgDiv.style.textAlign = "center"; // Center align the content

                                  // Create img element
                                  const img = document.createElement("img");
                                  img.src = imgURL;
                                  img.style.width = "100px";
                                  img.style.height = "100px";
                                  img.style.objectFit = "cover";

                                  // Create index input for arranging order
                                  const arrangeBtn =
                                    document.createElement("input");
                                  arrangeBtn.type = "number";
                                  arrangeBtn.value = image.length + index; // Set initial index value
                                  arrangeBtn.style.width = "60px";
                                  arrangeBtn.style.marginTop = "5px";

                                  // Handle change in the arrange index
                                  arrangeBtn.onchange = (e) => {
                                    const newIndex = parseInt(
                                      e.target.value,
                                      10
                                    );
                                    if (isNaN(newIndex) || newIndex < 0) {
                                      alert(
                                        "Please enter a valid non-negative number."
                                      );
                                      e.target.value =
                                        image[index]?.index || index;
                                      return;
                                    }

                                    // Update the state with the new order
                                    setimage((prevFiles) => {
                                      const updatedFiles = prevFiles.map(
                                        (img, i) => {
                                          if (i === index) {
                                            return { ...img, index: newIndex };
                                          }
                                          return img;
                                        }
                                      );

                                      // Sort the images based on the new index
                                      updatedFiles.sort(
                                        (a, b) => a.index - b.index
                                      );
                                      return updatedFiles;
                                    });

                                    // Optionally, re-render the gallery based on the new order
                                    // This part may require additional logic to clear and re-render the galleryDiv
                                    // depending on how your component is structured.
                                  };

                                  // Create remove button
                                  const removeBtn =
                                    document.createElement("button"); // Changed to button for better semantics
                                  removeBtn.innerText = "Remove";
                                  removeBtn.style.backgroundColor = "red";
                                  removeBtn.style.color = "white";
                                  removeBtn.style.border = "none";
                                  removeBtn.style.cursor = "pointer";
                                  removeBtn.style.marginTop = "5px";
                                  removeBtn.style.padding = "5px 10px"; // Added padding for better appearance

                                  // Remove the image and button on click
                                  removeBtn.onclick = () => {
                                    galleryDiv.removeChild(imgDiv);

                                    // Update the setimage state to remove the corresponding image
                                    setimage((prevFiles) =>
                                      prevFiles.filter((_, i) => i !== index)
                                    );
                                  };

                                  // Append img, arrange input, and remove button to imgDiv
                                  imgDiv.appendChild(img);
                                  imgDiv.appendChild(arrangeBtn);
                                  imgDiv.appendChild(removeBtn);

                                  // Append imgDiv to the gallery
                                  galleryDiv.appendChild(imgDiv);
                                });

                                setaddedproduct({
                                  ...addedproduct,
                                  imagesUrls: imagesURLS,
                                });

                                // Clear the input after the images are added
                                e.target.value = "";
                              }}
                              type="file"
                              style={{ display: "none" }} // Hide the input
                            />

                            <span
                              onClick={() =>
                                document.getElementById("file-input").click()
                              }
                              className="btn btn-success"
                              style={{
                                backgroundColor: "green",
                                color: "white",
                                border: "none",
                                cursor: "pointer",
                                marginBottom: "10px",
                              }}
                            >
                              +
                            </span>

                            <div
                              id="image-gallery"
                              className="image-gallery"
                            ></div>
                          </div>

                          <label htmlFor="">Choose Color</label>
                          <select
                            name=""
                            id=""
                            onChange={(e) => {
                              handlechangecolor(
                                { name: "color_code", value: e.target.value },
                                index
                              );
                              setcolorproperty((prev) => ({
                                ...colorproperty,
                                color_code: e.target.value,
                              }));

                              handlechangecolor(
                                {
                                  name: "color",
                                  value: colors?.filter(
                                    (item) => item?.color_code == e.target.value
                                  )[0]?.color_en,
                                },
                                index
                              );
                              console.log(
                                colors?.filter(
                                  (item) => item?.color_code == e.target.value
                                )
                              );
                              setcolorproperty({
                                ...colorproperty,
                                color: colors?.filter(
                                  (item) => item?.color_code == e.target.value
                                )[0]?.color_en,
                              });

                              handlechangecolor(
                                {
                                  name: "color_ar",
                                  value: colors?.filter(
                                    (item) => item?.color_code == e.target.value
                                  )[0]?.color_ar,
                                },
                                index
                              );
                              setcolorproperty((prev) => ({
                                ...colorproperty,
                                color_ar: e.target.value,
                              }));
                            }}
                          >
                            <option value={""}>
                              {language == "ar" ? "" : ""}
                            </option>
                            {colors?.map((item) => {
                              return (
                                <option value={item?.color_code}>
                                  {language == "ar"
                                    ? item?.color_ar
                                    : item?.color_en}
                                </option>
                              );
                            })}
                          </select>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              margin: "20px 0px",
                            }}
                          >
                            <h4>
                              {language == "ar"
                                ? "إضافة خاصية"
                                : "Add Attribute"}
                            </h4>
                            <AiOutlinePlus
                              onClick={() => {
                                // Clone the ColorsArr array
                                let allColorsArr = [...ColorsArr];

                                // Ensure there is a valid ColorProperities array to work with
                                if (allColorsArr[0]?.ColorProperities) {
                                  // Clone the ColorProperities array
                                  let list2 = [
                                    ...allColorsArr[0].ColorProperities,
                                  ];

                                  // Add the new object to the cloned array
                                  list2.push({
                                    label: "",
                                    label_ar: "",
                                    Properity_values: [
                                      { label: "", label_ar: "" },
                                    ],
                                  });

                                  // Update the first object's ColorProperities in allColorsArr
                                  allColorsArr[0] = {
                                    ...allColorsArr[0],
                                    ColorProperities: list2,
                                  };

                                  // Update the state
                                  setColorsArr(allColorsArr);
                                }
                              }}
                            />
                          </div>
                          {item?.ColorProperities?.map((it, ind) => {
                            return (
                              <div className="form_attr">
                                <form
                                  onSubmit={(e) => {
                                    e.preventDefault();
                                    // handleaddproperity(it, item, index, ind);
                                  }}
                                  className="from_Shipping "
                                  action=""
                                >
                                  <div
                                    style={{
                                      width: "20px",
                                      height: "20px",
                                      borderRadius: "50%",
                                      backgroundColor: `${item.color_code}`,
                                    }}
                                  ></div>
                                  <label htmlFor="">
                                    {language == "ar"
                                      ? " - إسم الخاصيه بالإنجليزيه" + (ind + 1)
                                      : "Attribute In English - " + (ind + 1)}
                                  </label>
                                  <input
                                    name="label"
                                    onChange={(e) => {
                                      setaddobj({
                                        ...addobj,
                                        label: e.target.value,
                                      });
                                      handlechagepropform(e, ind, index);
                                    }}
                                    type="text"
                                  />
                                  <label htmlFor="">
                                    {language == "ar"
                                      ? " - إسم الخاصيه بالعربيه" + (ind + 1)
                                      : "Attribute In Arabic - " + (ind + 1)}
                                  </label>
                                  <input
                                    name="label_ar"
                                    onChange={(e) => {
                                      setaddobj({
                                        ...addobj,
                                        label_ar: e.target.value,
                                      });
                                      handlechagepropform(e, ind, index);
                                    }}
                                    type="text"
                                  />
                                </form>
                                {it?.Properity_values?.length > 0 ? (
                                  <>
                                    {it?.Properity_values?.map(
                                      (itprop, indpro) => {
                                        return (
                                          <form
                                            onSubmit={(e) => {
                                              e.preventDefault();
                                              handleaddPropsValue(
                                                item,
                                                index,
                                                it,
                                                ind,
                                                itprop,
                                                indpro
                                              );
                                            }}
                                            style={{ margin: "20px 0px" }}
                                            className="from_Shipping"
                                          >
                                            <label htmlFor="">
                                              {language == "ar"
                                                ? "قيمة الخاصية بالانجليزية"
                                                : "Attribute Value In English"}
                                            </label>
                                            <input
                                              name="label"
                                              onChange={(e) => {
                                                handlechangeprpvalue(
                                                  e,
                                                  item,
                                                  index,
                                                  it,
                                                  ind,
                                                  itprop,
                                                  indpro
                                                );
                                                setaddobjpropval({
                                                  ...addobjpropval,
                                                  label: e.target.value,
                                                });
                                              }}
                                              placeholder="example: storage 256"
                                              type="text"
                                            />
                                            <label htmlFor="">
                                              {language == "ar"
                                                ? "قيمة الخاصية بالعربية"
                                                : "Attribute Value In arabic"}
                                            </label>
                                            <input
                                              name="label_ar"
                                              onChange={(e) => {
                                                handlechangeprpvalue(
                                                  e,
                                                  item,
                                                  index,
                                                  it,
                                                  ind,
                                                  itprop,
                                                  indpro
                                                );
                                                setaddobjpropval({
                                                  ...addobjpropval,
                                                  label_ar: e.target.value,
                                                });
                                              }}
                                              placeholder="example: التخزين 256"
                                              type="text"
                                            />
                                          </form>
                                        );
                                      }
                                    )}
                                  </>
                                ) : null}
                              </div>
                            );
                          })}
                          {/* <label htmlFor="">
                            {language == "ar" ? "كود اللون" : "Color Code"}
                          </label>
                          <input
                            name="color_code"
                            type="color"
                            placeholder={
                              language == "ar" ? "كود اللون" : "Color Code"
                            }
                            onChange={(e) => {
                              handlechangecolor(e, index);
                              setcolorproperty({
                                ...colorproperty,
                                color_code: e.target.value,
                              });
                            }}
                          /> */}
                          {/* <label htmlFor="">
                            {language == "ar"
                              ? "إسم اللون بالانجليزيه"
                              : "Color In English"}
                          </label>
                          <input
                            name="color"
                            type="text"
                            placeholder={
                              language == "ar"
                                ? "إسم اللون بالانجليزيه"
                                : "Color In English"
                            }
                            onChange={(e) => {
                              handlechangecolor(e, index);
                              setcolorproperty({
                                ...colorproperty,
                                color: e.target.value,
                              });
                            }}
                          /> */}
                          {/* <label htmlFor="">
                            {language == "ar"
                              ? "إسم اللون بالعربيه"
                              : "Color In Arabic"}
                          </label>
                          <input
                            name="color_ar"
                            type="text"
                            placeholder={
                              language == "ar"
                                ? "إسم اللون بالعربيه"
                                : "Color In Arabic"
                            }
                            onChange={(e) => {
                              handlechangecolor(e, index);
                              setcolorproperty({
                                ...colorproperty,
                                color_ar: e.target.value,
                              });
                            }}
                          /> */}
                          {/* {item.colloading ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <Loader />
                            </div>
                          ) : (
                            <button>
                              {language == "ar" ? "إضافه" : "Add"}
                            </button>
                          )} */}
                        </form>
                      );
                    })}
                    {productloading ? (
                      <div
                        style={{
                          textAlign: "center",
                          marginTop: "10px",
                        }}
                      >
                        <Loader />
                      </div>
                    ) : (
                      <div className="flexDiv d-flex gap-3 justify-content-end align-items-center">
                        <span
                          className="btn btn-danger"
                          onClick={() => setPreview(true)}
                        >
                          {language == "ar" ? "مراجعة" : "Preview"}
                        </span>
                        <button className="btn btn-success">
                          {language == "ar" ? "حفظ" : "Save"}
                        </button>
                      </div>
                    )}
                    <Modal open={preview} onCancel={() => setPreview(false)}>
                      <ProductPreview
                        ColorsArr={ColorsArr}
                        addedProduct={addedproduct}
                      />
                    </Modal>
                  </form>
                  {/* {showcorlorform ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        margin: "20px 0px",
                      }}
                    >
                      <h4>
                        {language == "ar" ? "إضافة لون جديد" : "Add New Color"}
                      </h4>
                      <h4
                        onClick={() => {
                          // setColorsArr([]);
                          setColorsArr([
                            ...ColorsArr,
                            {
                              product_id: AddedProductId,
                              images: "",
                              // price:'',
                              // new_price:'',
                              // discount:'',
                              available_quantity: "",
                              color_code: "",
                              color: "",
                              color_ar: "",
                              ColorProperities: [],
                              colloading: false,
                            },
                          ]);
                        }}
                      >
                        <AiOutlinePlus style={{ cursor: "pointer" }} />
                      </h4>
                    </div>
                  ) : null} */}
                </>
              ) : choice == "adduser" ? (
                <Form inputs={user_input} submitFunction={add_user} />
              ) : choice == "address" ? (
                <Form inputs={address_input} submitFunction={add_offer} />
              ) : choice == "category" ? (
                // <Form inputs={categories_input} submitFunction={add_category} />
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleaddcategory();
                  }}
                  className="formaddproduct"
                  action=""
                >
                  <label htmlFor="">
                    {language == "ar"
                      ? "إسم الفئه بالانجليزيه"
                      : "Category Name In English"}
                  </label>
                  <input
                    onChange={(e) => {
                      setaddedcategory({
                        ...addedcategory,
                        title: e.target.value,
                      });
                    }}
                    type="text"
                    placeholder={
                      language == "ar"
                        ? "إسم الفئه بالانجليزيه"
                        : "Category Name In English"
                    }
                  />
                  <label htmlFor="">
                    {language == "ar"
                      ? "إسم الفئه بالعربيه"
                      : "Category Name In Arabic"}
                  </label>
                  <input
                    onChange={(e) => {
                      setaddedcategory({
                        ...addedcategory,
                        title_ar: e.target.value,
                      });
                    }}
                    type="text"
                    placeholder={
                      language == "ar"
                        ? "إسم الفئه بالعربيه"
                        : "Category Name In Arabic"
                    }
                  />
                  <label htmlFor="">
                    {language == "ar" ? "الصور" : "Images"}
                  </label>
                  <div className="updiv">
                    <input
                      onChange={(e) => {
                        setimage(e.target.files[0]);
                      }}
                      type="file"
                    />
                    {imageloading ? (
                      <Icon icon="eos-icons:loading" />
                    ) : (
                      <button
                        onClick={() => {
                          handleuploadimage();
                        }}
                      >
                        {language == "ar" ? "رفع الصورة" : "Upload Image"}
                      </button>
                    )}
                  </div>
                  <button>{language == "ar" ? "أضف" : "Add"}</button>
                </form>
              ) : choice == "social" ? (
                <Form inputs={categories_input} submitFunction={add_category} />
              ) : choice == "contact" ? (
                <Form inputs={contact_input} submitFunction={add_contact} />
              ) : choice == "banner" ? (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleaddbanner();
                  }}
                  className="formaddproduct"
                >
                  <label htmlFor="">
                    {language == "ar"
                      ? "الاسم بالانجليزيه"
                      : "title In English"}
                  </label>
                  <input
                    onChange={(e) => {
                      setbannerdata({ ...bannerdata, title: e.target.value });
                    }}
                    type="text"
                    placeholder={
                      language == "ar"
                        ? "الإسم بالإنجليزيه"
                        : "title In English"
                    }
                  />
                  <label htmlFor="">
                    {language == "ar" ? "الاسم بالعربيه" : "title In Arabic"}
                  </label>
                  <input
                    onChange={(e) => {
                      setbannerdata({
                        ...bannerdata,
                        title_ar: e.target.value,
                      });
                    }}
                    type="text"
                    placeholder={
                      language == "ar" ? "الإسم بالعربيه" : "title In arabic"
                    }
                  />
                  <label htmlFor="">
                    {language == "ar" ? "النص بالإنجليزيه" : "text In English"}
                  </label>
                  <input
                    onChange={(e) => {
                      setbannerdata({ ...bannerdata, text: e.target.value });
                    }}
                    type="text"
                    placeholder={
                      language == "ar" ? "النص بالإنجليزيه" : "text In English"
                    }
                  />
                  <label htmlFor="">
                    {language == "ar" ? "النص بالعربيه" : "text In arabic"}
                  </label>
                  <input
                    onChange={(e) => {
                      setbannerdata({ ...bannerdata, text_ar: e.target.value });
                    }}
                    type="text"
                    placeholder={
                      language == "ar" ? "النص بالعربيه" : "text In arabic"
                    }
                  />
                  <label htmlFor="">
                    {language == "ar" ? "الصوره" : "image"}
                  </label>
                  <div className={language == "ar" ? "updiv rtl" : "updiv"}>
                    <input
                      onChange={(e) => {
                        setimage(e.target.files[0]);
                      }}
                      type="file"
                    />
                    {imageloading ? (
                      <Icon icon="eos-icons:loading" />
                    ) : (
                      <button
                        onClick={() => {
                          handleuploadafteredit();
                        }}
                      >
                        {language == "ar" ? "رفع الصورة" : "Upload Image"}
                      </button>
                    )}
                  </div>
                  <label htmlFor="">
                    {language == "ar" ? "اللينك" : "Link"}
                  </label>
                  <input
                    onChange={(e) => {
                      setbannerdata({ ...bannerdata, link: e.target.value });
                    }}
                    type="text"
                    placeholder={language == "ar" ? "اللينك" : "Link"}
                  />
                  <button>
                    {addloading ? <Loader /> : language == "ar" ? "أضف" : "Add"}
                  </button>
                </form>
              ) : null}
            </div>
          </div>
        }
      />
      <button>{language == "ar" ? "حفظ" : "Save"}</button>
    </div>
  );
}

export default Addition;
